<template>
    <div class="goods-swipe">
      <van-swipe  @change="handleBanner"  :autoplay="0"  :show-indicators="false" ref="swipe" class="m-swipe">
        <van-swipe-item v-if="hasVideo" >
            <video-player
                class="video-player vjs-custom-skin video"
                ref="videoPlayer"
                autoplay
                playsinline
                x5-playsinline
                webkit-playsinline
                :mtt-playsinline="true"
                :x5-video-player-type="h5" 
                x5-video-player-fullscreen
                :options="playerOptions"
                @canplay="onPlayerCanplay($event)"
                @ended="onPlayerEnded($event)"
            ></video-player>
        </van-swipe-item>
        <van-swipe-item v-for="(image, index) in images" :key="index" >
          <img v-lazy="image.url" class="m-banner" />
          <div class="eleven" v-if="goodsInfo.pre_info.is_pre == 0 && index == 0 && elevenShow && goodsInfo.merchant_id == 1"></div>
          <div class="bargain" v-if="goodsInfo.id == '3751'">
            <div class="discount">
              <img src="@/assets/bargain/off.png" alt="">
            </div>
            <div class="surplus">
              <p>打折套票剩余</p>
              <p><span>{{goodsInfo.goods_sku[0].sku_stock.stock}}</span> 张</p>
            </div>
          </div>
          <div class="swiper_pre" v-if="($route.query.pre && $route.query.pre == 1 || goodsInfo.pre_info.is_pre == 1) && index == 0">
            <img src="@/assets/goods/pre_bar.png">
            <p><span>$</span>{{(goodsInfo.pre_info.pre_min_price - 0).toFixed(2)}}</p>
            <p>现货价 <span>$</span>{{(goodsInfo.min_price - 0).toFixed(2)}}</p>
            <p>节省 $<span>{{(goodsInfo.min_price - 0 - goodsInfo.pre_info.pre_min_price).toFixed(2)}}</span></p>
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- 切换 视频 <——> 轮播 -->
      <div class="toggle" :style="{'justify-content': $route.query.pre && $route.query.pre == 1 ? 'flex-end' : 'center'}">
        <span class="toggle-1" @click="handleToggle()" v-if="hasVideo">视频</span>
        <span class="toggle-2">{{index}}/{{total}}</span>
      </div>

      <!-- 左右指向 -->
      <img @click="onChnage(1)" src="@/assets/public/left.png" alt="left" class="toggle-img">
      <img @click="onChnage(2)" src="@/assets/public/right.png" alt="right" class="toggle-img">

    </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

export default {
    props: {
        hasVideo: {
          type: Boolean,
          default: false,
        },
        images: {
          type: Array,
          default: () => {
            return []
          },
        },
        videoData: {
          type: Object,
          default: () => {
            return {}
          }
        },
        goodsInfo: {
          type: Object,
          default: () => {
            return {}
          }
        }
    },
    data(){
      return {
        module:2,
        index:1,        // 轮播的index
        total:0,        // 轮播总计
        h5:'h5-page',
        playerOptions : {
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            aspectRatio: '375:375', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            poster: '', //你的封面地址
            sources: [{
              type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              src: '' //url地址
            }],
            width: document.documentElement.clientWidth, //播放器宽度
            notSupportedMessage: '视频加载失败', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controls:false,
        },
        player: null,
        elevenShow: true // 圣诞活动边框显示
      }
    },
    methods: {
      // 轮播图切换事件
      handleBanner(index){
        this.index = index + 1
        if(index != 0){
          if(this.hasVideo)this.player.pause()
        }else{
          if(this.hasVideo)this.player.play()
        }
      },
      // 点击视频跳转
      handleToggle(){
        this.$refs.swipe.swipeTo(0)
      },
      // 可以播放视频
      onPlayerCanplay(player) {
        console.log('player Canplay!', player)
        // player.play()
      },
      // 播放结束
      onPlayerEnded(player) {
        player.currentTime(0)
      },

      // 点击左右指向 切换轮播图
      onChnage(t){
        if(t == 1){
          this.$refs.swipe.prev() 
        }else{
          this.$refs.swipe.next()
        }
      }
    },
    watch: {
      hasVideo(val) {
        if(val) {
          this.$nextTick(() => {
            this.player = this.$refs.videoPlayer.player
          })
        }
      },
      videoData: {
        handler(val) {
          if(val) {
            this.playerOptions.poster = this.$store.state.ossUrl + val.video_pic
            this.playerOptions.sources[0].src = this.$store.state.ossUrl + val.url
          }
        },
        deep: true
      },
      images: {
        handler(val) {
          if(val) {
            this.$nextTick(() => {
              if(this.player && this.hasVideo){
                this.total = this.images.length + 1
                let timer = setTimeout(()=> {
                  this.player.play()
                  clearTimeout(timer)
                },300)
              }else{
                this.total = this.images.length
              }
            })
          }
        },
        deep: true
      }
    },
}
</script>

<style lang="less" scoped>
.goods-swipe {
  width: 100vw;
  height: 100vw;
  background-color: #fff;
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .wrapper {
    width: 200vw;
    height: 100vw;

    .video {
      width: 100vw;
      height: 100vw;
      
    }

  }
  .m-swipe {
    width: 100vw;
    height: 100vw;
    .m-banner {
      width: 375px;
      height: 375px;
    }
  }

  /deep/.vjs-tech{object-fit: cover;}
  /deep/.vjs-poster{background-size: cover;}

  /deep/ .vjs-custom-skin > .video-js .vjs-big-play-button {
    width: 2em;
    left: 56%;
    border-radius: 50%;
    border: none;
    z-index: 999;
  }

  .toggle {
    position: absolute;
    bottom: 8px;
    width: 100%;
    height: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 10px;
    box-sizing: border-box;
    z-index: 14;
    .toggle-1 {
      display: inline-block;
      background: rgba(51, 51, 51, .3);
      color: #fff;
      padding: 0 5px;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 20px;
      margin-right: 10px;
    }
    .toggle-2 {
      display: inline-block;
      background-image: url('./../../assets/goods/swipe-bg@2x.png');
      background-size: 100% 100%;
      color: #fff;
      width: 32px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  .toggle-img {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 170px;
    left: 20px;
    width: 32px;
    height: 32px;
    &:nth-of-type(2){
      right:20px;
      left:inherit
    }
  }
  .eleven{position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: url('../../assets/index/eleven.png') no-repeat center;background-size: 100% 100%;}
  .bargain {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 12px;
    background: url('../../assets/bargain/tip.png') no-repeat;
    background-size: 100% 100%;
    .discount {
      margin-left: 12px;
      margin-top: 5px;
      width: 195px;
      height: 46px;
      img {
          width: 100%;
          height: 100%;
      }
    }
    .surplus {
      color: #5A0000;
      margin-right: 20px;
      text-align: center;
      p:nth-child(2) {
        span {
          font-weight: bold;
        }
      }
    }
  }
  .swiper_pre{position: absolute;bottom:0;left:0;width:100%;height:106px;
    img{width:100%;}
    p{position: absolute;top:45px;
      span{font-size: 12px;}
      &:nth-of-type(1){font-size: 22px;color:#fff;left:16px;font-weight: 600;
        span{font-size: 14px;}
      }
      &:nth-of-type(2){font-size: 16px;color:#fff;top:75px;left:16px;text-decoration: line-through;}
      &:nth-of-type(3){font-size: 14px;color:#fff;top:76px;left:172px;
        span{font-size: 18px;font-weight: 600;}
      }
    }
  }
}
  
</style>